<template>
  <div>
    <div class="text-3xl mb-5" data-cy="header-permissions">
      This link has expired.
    </div>
  </div>
</template>

<script>
export default {
  name: "LinkExpired",
};
</script>
